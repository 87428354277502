
import { Vue, Component, Watch } from 'vue-property-decorator';
import { RemessaService } from '@/core/services/financeiro';
import { Remessa } from '@/core/models/financeiro';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';

@Component
export default class ListaTipoReceita extends mixins(Vue,ListPage) {
  item = new Remessa();
  service = new RemessaService();

  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0; 

  titulo: string = 'Remessa Bancária';
  subTitulo: string = 'Lista das Remessas cadastradas no Sistema';
 
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [ 
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Tipo', value: 'tipo.nome' },
    { text: 'Data de criação', value: 'dataCriacao' },
    { text: 'Processado', value: 'isProcessado' },
  ];

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, '', '', '', 'Tipo').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  Novo(){
    this.item = new Remessa();
    this.dialogCadastro = true;
  }

  Editar(item) {
    this.service.ObterPorId(item.id, "Transacoes.Parcela.Baixas, Transacoes.Situacao")
      .then((res) => {       
        this.item = res.data;
        this.dialogCadastro = true;
      },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  }

  DownloadArquivo(arquivo){
    const link = document.createElement("a");
    link.href = `data:${arquivo.tipo};base64,${arquivo.dados}`;
    link.type = arquivo.tipo;
    link.download = arquivo.nome;
    link.click();
    link.remove();
  }; 

  ProcessarRemessa(item){
    this.service.ObterPorId(item.id, "Transacoes.Parcela.Baixas, Transacoes.Situacao")
      .then((res) => {       
        this.item = res.data;
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
      
    /*this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja processar a remessa?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Processar(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.DownloadArquivo(res.data);
              this.Atualizar();
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      }, 
      
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          "Operação realizada com sucesso!",
          result.value.status == 200 ? "success" : "warning"
        ); 
      } 
    });*/
  }

  Excluir(item){
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },  
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      }, 
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    }); 
  }
   
  mounted(){ 
    
  } 
}
